<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-slide-y-transition appear>
        <v-card
          min-width="320"
          light
        >
          <v-card-title>
            Login using Okta
          </v-card-title>
          <v-card-text>
            <v-btn
              color="primary"
              depressed
              large
              block
              dark
              @click="login"
            >
              Let's Go
            </v-btn>
          </v-card-text>
        </v-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PagesLogin',
  methods: {
    async login () {
      await this.$auth.signInWithRedirect({ originalUri: this.$route.query.redirect || '/' })
    }
  }
}
</script>
